import * as s from './styles/button.module.scss';
import React, { ReactElement } from 'react';
import { Link } from 'gatsby';

interface Props {
  to?: string
  href?: string
  secondary?: boolean
  outline?: boolean
  className?: string
  children: React.ReactNode
}

export default function Button({ to, href, secondary, outline, children, className }: Props): ReactElement {
  const getClass = (): string => {
    return `${s.Button} ${secondary ? s.Button___secondary : ''} ${outline ? s.Button___outline : ''} ${className}`
  }

  return to
    ? (
      <Link to={to} className={getClass()}>
        {children}
      </Link>
    )
    : (
      <a {...{ href: href, className: getClass() }}>
        {children}
      </a>
    )
}
