import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

interface Props {
  title: string
  description?: string
  grayBody?: boolean
}

export default function SEO({ title, description, grayBody }: Props): ReactElement {
  const { site: { siteMetadata: site } } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            keywords
          }
        }
      }
    `
  )

  const seo = {
    title: title || site.title,
    description: description || site.description,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={site.titleTemplate}
      htmlAttributes={{
        lang: 'pt-BR'
      }}
      bodyAttributes={grayBody ? {
        class: 'u-background-gray',
      } : undefined}
      meta={[
        {
          name: 'description',
          content: seo.description,
        },
        {
          name: 'keywords',
          content: site.keywords,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: seo.description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
      defer={false} />
  )
}
