import * as s from './styles/footer.module.scss';
import React, { ReactElement } from 'react';
import { Link } from 'gatsby';

export default function Footer(): ReactElement {
  return (
    <footer className={s.Footer}>
      <ul className={`${s.Footer_list} u-margin-bottom`}>
        <li>
          <h1 className="topic u-margin-bottom-small">Produtos</h1>
          <ul className={s.Footer_links}>
            <li className="info">
              <Link to="/produtos/mr1" className={s.Footer_link}>MR1</Link>
            </li>
            <li className="info">
              <Link to="/produtos/mre" className={s.Footer_link}>MRE</Link>
            </li>
            <li className="info">
              <Link to="/produtos/mrmobile" className={s.Footer_link}>MRMobile</Link>
            </li>
          </ul>
        </li>
        <li>
          <h1 className="topic u-margin-bottom-small">Recursos</h1>
          <ul className={s.Footer_links}>
            <li className="info">
              <Link to="/sobre" className={s.Footer_link}>Sobre</Link>
            </li>
            <li className="info">
              <Link to="/downloads" className={s.Footer_link}>Downloads</Link>
            </li>
            <li className="info">
              <Link to="/privacidade" className={s.Footer_link}>Privacidade</Link>
            </li>
          </ul>
        </li>
      </ul>
      <p className={s.Footer_legal}>
        <small>
          Copyright © {new Date().getFullYear()} Sysrs Tecnologia da Informação Ltda<br />
          Telefone: +55 51 3524-7405<br />
          Rua Domingos de Almeida, 135 Sala 1102 - Centro - Novo Hamburgo - RS - Brasil<br />
        </small>
      </p>
    </footer>
  )
}
