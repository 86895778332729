import Footer from './footer';
import Navbar from './navbar';
import React, { ReactElement } from 'react';
import SEO from './seo';

interface Props {
  title: string
  children: React.ReactNode
  description?: string
  gray?: boolean
  transparent?: boolean
}

export default function Layout({
  children,
  transparent,
  title,
  description,
  gray,
}: Props): ReactElement {
  return (
    <>
      <SEO title={title} description={description} grayBody={gray} />
      <Navbar transparent={transparent} />
      {children}
      <Footer />
    </>
  )
}
