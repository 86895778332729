import * as s from './styles/navbar.module.scss';
import Button from './button';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
  transparent?: boolean,
}

export default function Navbar({ transparent }: Props): ReactElement {
  const [isTransparent, setTransparent] = useState(transparent)

  const changeTransparent = () => {
    if ((window.scrollY === 0) !== isTransparent)
      setTransparent(window.scrollY === 0)
  }

  useEffect(() => {
    if (transparent) {
      changeTransparent()
      window.addEventListener('scroll', changeTransparent)
      return () => window.removeEventListener('scroll', changeTransparent)
    }
  })

  return (
    <nav id="navbar" className={`${s.Nav} ${isTransparent ? s.Nav___transparent : ''}`}>
      <div className={s.Nav_box}>
        <Link to="/" className={s.Nav_logoLink}>
          <StaticImage
            src="../assets/images/logo.png"
            alt="Logo SysRS"
            objectFit="contain"
            placeholder="none"
            className={s.Nav_logo} />
          <StaticImage
            src="../assets/images/logo-white.png"
            alt="Logo SysRS"
            objectFit="contain"
            placeholder="none"
            className={`${s.Nav_logo} ${s.Nav_logo___white}`} />
        </Link>
        <label htmlFor="nav__checkbox" className={s.Nav_hamburgerBox}>
          <span className={`material-icons ${s.Nav_hamburger}`}>menu</span>
        </label>
        <input type="checkbox" id="nav__checkbox" className={s.Nav_checkbox} />
        <ul className={s.Nav_list}>
          <li className={s.Nav_link}>
            <Link to="/" className={s.Nav_linkText}>
              Home
            </Link>
          </li>
          <li className={s.Nav_link}>
            <Link to="/downloads" className={s.Nav_linkText}>
              Downloads
            </Link>
          </li>
          <li className={s.Nav_link}>
            <Link to="/produtos" className={s.Nav_linkText}>
              Produtos
            </Link>
          </li>
          <li className={s.Nav_link}>
            <Link to="/sobre" className={s.Nav_linkText}>
              Sobre
            </Link>
          </li>
          <li className={s.Nav_link}>
            <a href="http://webmail.sysrs.com.br" rel="noopener" target="_blank" className={s.Nav_linkText}>
              Webmail
            </a>
          </li>
        </ul>
        <div className={s.Nav_buttonBox}>
          <Button href="mailto:sysrs@sysrs.com.br" className={s.Nav_button} outline>
            <span className={s.Nav_buttonText}>
              Entre em contato
            </span>
            <span className={`material-icons ${s.Nav_buttonIcon}`}>email</span>
          </Button>
        </div>
      </div>
    </nav>
  )
}
